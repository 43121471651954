/*
This is the App.css file
 */


html {
  height: 100%;
  /* color: white; */
  /* overflow: hidden; */
}
body {
  min-height: 100%;
  overflow: hidden;
  /* color: white; */
}

#success-page a,
#body-container a {
color: white;
}
#scroll-dialog-description a {
  color:rgba(255, 255, 255, 0.7);
}

table, td, th {
  border: 1px solid;
  border-collapse: collapse;
  height: 70px;
  padding: 10px;

}

tr {
  min-height: 60px;
}


img.desktop {
  display: block;
  max-width:300px;
  max-height:600px;
  width: auto;
  height: auto;
  object-fit: contain;
  /* border-radius: 25px; */

}

div.wxyz {
  height:'50px';
  width:'50px';
  border-radius:'25px';
  background-color:'pink';
  margin:'5px';
}

img {
  transition: 2s;
  -webkit-transform: 2s;
}

img:hover {
  transform: scale(1.025);
}

.scroll-container {
  width:'100%';
  height:'100%';
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow:'auto';
  background-color: blue;
}
.scroll-container::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

#loadingDiv {
  height: 213px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#instagramReelsWrap { width: 120px; height: 213px; padding: 0; overflow: hidden; display: block; margin: auto;}
#instagramReelsFrame { width: 320px; height: 568px; border: 1px solid black;  }
#instagramReelsFrame {
    -ms-zoom: 0.375;
    -moz-transform: scale(0.375);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.375);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.375);
    -webkit-transform-origin: 0 0;
}

#instagramFeedWrap { position:relative; width: 120px; height: 213px; padding: 0; overflow: hidden; display: block; margin: auto;}
#instagramFeedFrame { width: 320px; height: 640px; border: 1px solid black; top:-15px; position:relative; }
#instagramFeedFrame {
    -ms-zoom: 0.375;
    -moz-transform: scale(0.375);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.375);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.375);
    -webkit-transform-origin: 0 0;
}

#desktopInstagramReelsWrap { width: 320px; height: 567px; padding: 0; overflow: hidden; display: block; margin: auto;}
#desktopInstagramReelsFrame { width: 320px; height: 567px; overflow: hidden; display:block;}

#desktopInstagramFeedWraper {width: 320px; height:567;}
#desktopInstagramFeedWrap { position:relative; width: 320px; height: 567px; padding: 0; overflow: hidden; display: block; margin: auto; background-color: pink;}
#desktopInstagramFeedFrame { width: 320px; height: 640px;  display:block; position: relative; top:-40px; background-color: pink;}
/* #desktopInstagramReelsFrame {
    -ms-zoom: 0.375;
    -moz-transform: scale(0.375);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.375);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.375);
    -webkit-transform-origin: 0 0;
}
 */

#navUnderline {
  height: '2px';
   width: '50px';
  background: linear-gradient(to right, '#1DB954' 50%, '#282828' 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

#navUnderline.selected {
  background-position: left bottom;
}

html {
  /* overflow: scroll; */
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}


#OverflowContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x:hidden;
}

#OverflowWrapper {
  margin: auto 0;
  width:100%;
}
